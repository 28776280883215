import React, { Component } from 'react'

import slugify from 'slugify'

import './TestimonialCard.scss'
import Img from 'gatsby-image'

import { Link } from 'gatsby'

export default class TestimonialCard extends Component {
  render() {
    const { data, noShadow, noMinHeight, noLink } = this.props

    const {
      html,
      frontmatter: { name, description, image },
    } = data

    const anchor = slugify(name)

    return (
      <div
        className={`c-card  py-3 px-3 py-lg-4 bg-white ${
          noShadow ? '' : 'shadow'
        } ${noMinHeight ? 'c-card--no-min-height' : 'c-card--min-height'}`}
      >
        <span className="c-card__anchor" id={anchor} />
        <div className="d-sm-flex align-items-start">
          <Img
            fixed={image.childImageSharp.fixed}
            className="c-card-avatar d-block flex-shrink-0  img-circle"
            style={{ width: '60px' }}
          />
          <div className="pl-sm-2 pt-sm-1">
            <h2 className="c-card__author text-uppercase">{name}</h2>
            <div className="c-card__author-description text-mid">
              {description}
            </div>
            <div
              className="c-card__quote mt-3"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
        {!noLink && (
          <Link className="c-card__link" to={`/testimonials#${anchor}`}>
            Read more about {name}
          </Link>
        )}
      </div>
    )
  }
}
