import React from 'react'
import HeroSingle from '../components/HeroSingle/index'
import Layout from '../components/Layout/index'
import { graphql } from 'gatsby'
import MassiveTitle from '../components/MassiveTitle'
import SEO from '../components/SEO'

import CTABlock from '../components/CTABlock'
import TestimonialCard from '../components/TestimonialSlider/TestimonialCard'
import CTABanner from '../components/CTABanner'

class Index extends React.Component {
  render() {
    const { data, location } = this.props

    return (
      <Layout location={location}>
        <SEO path={location.pathname} frontmatter={data.page.frontmatter} />
        <div className="bg-light pb-md-5">
          <section className="position-relative">
            <HeroSingle data={data.page} />
            <CTABlock
              data={data.page.frontmatter.cta_block}
              className="u-pull-up"
              compact
            />
          </section>
          <div className="u-pull-up">
            <MassiveTitle modifiers={['z0']}>Testimonials</MassiveTitle>
          </div>
          <div className="container">
            {data.testimonials.edges.map(({ node }) => (
              <div className="col-12 my-3 col" key={node.id}>
                <TestimonialCard noLink noShadow noMinHeight data={node} />
              </div>
            ))}
          </div>
        </div>
        <div className="bg-light c-footer">
          <CTABanner data={data.page.frontmatter.cta_banner} />
        </div>
      </Layout>
    )
  }
}

export default Index

export const TestimonialsOverviewQuery = graphql`
  query {
    page: markdownRemark(frontmatter: { id: { eq: "testimonials" } }) {
      ...SEO
      ...ctaBlock
      ...ctaBanner
      frontmatter {
        title
        introTitle
      }
    }

    testimonials: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "testimonial" } } }
      sort: { order: ASC, fields: frontmatter___priority }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            name
            description
            image {
              childImageSharp {
                fixed(width: 60, height: 60) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
